/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://iwcsigef6zfudjvqva6cqx54ve.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-tsun24egwzdytfpmbape6eoza4",
    "aws_cloud_logic_custom": [
        {
            "name": "optimaLambdaNotifyUserOfEventApi",
            "endpoint": "https://dmoymcowd3.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "optimaLambdaPublicQueryApi",
            "endpoint": "https://v3kg7t1wh7.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        },
        {
            "name": "optimaManageUserApi",
            "endpoint": "https://hcm0kmjjwl.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:90bff510-3ab2-4f14-9e57-60f4893bfa93",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_Tr19d83oF",
    "aws_user_pools_web_client_id": "4bk87ivr3iqccl8365p33vuf2e",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "optimaeventsapp125b2370c76e4a94b45aa3f2ec77ce64131429-master",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
