import Vue from "vue";
import Vuex from "vuex";
import { Auth } from "aws-amplify";

Vue.use(Vuex);

export default new Vuex.Store({
  state: { user: null, roles: null },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setRole(state, payload) {
      state.roles = payload;
    },
  },
  actions: {
    async logout({ commit }) {
      commit("setUser", null);
      return await Auth.signOut();
    },
    async login({ commit }, { username, password }) {
      try {
        await Auth.signIn({
          username,
          password,
        });

        const userInfo = await Auth.currentUserInfo();
        commit("setUser", userInfo);

        const authenticatedUser = await Auth.currentAuthenticatedUser();
        const userRoles =
          authenticatedUser.signInUserSession.accessToken.payload[
            "cognito:groups"
          ];
        commit("setRole", userRoles);
        return Promise.resolve("Success");
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async authAction({ commit }) {
      const userInfo = await Auth.currentUserInfo();
      commit("setUser", userInfo);

      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const userRoles =
        authenticatedUser.signInUserSession.accessToken.payload[
          "cognito:groups"
        ];
      commit("setRole", userRoles);
    },
  },
  getters: {
    user: (state) => state.user,
    roles: (state) => state.roles,
  },
});
