<template>
  <div>
    <v-app-bar color="#3a3a3a" dark flat app v-if="user">
      <v-toolbar-title>Optima Events App</v-toolbar-title>
      <v-spacer style="z-index: 1"></v-spacer>
      <v-toolbar-items>
        <v-btn color="#fff" text to="/">Home</v-btn>
        <v-btn color="#fff" text to="/company" v-if="isAdminRole"
          >Company</v-btn
        >
        <v-btn color="#fff" text to="/operations" v-if="isAdminRole"
          >Operations</v-btn
        >
        <v-btn color="#fff" text to="/templates" v-if="isAdminRole"
          >Comms Templates</v-btn
        >
        <v-btn color="#fff" text to="/email-extractor" v-if="isAdminRole"
          >Email Extractor</v-btn
        >
        <v-btn
          color="#fff"
          text
          to="/events"
          v-if="isAdminRole"
          style="margin-right: 55px"
          >Events</v-btn
        >
        <v-btn
          color="#fff"
          text
          to="/consultant"
          v-else-if="isConsultantRole"
          style="margin-right: 55px"
          >Consultant</v-btn
        >
        <v-speed-dial
          style="right: 20px; top: 10px"
          fixed
          v-model="fab"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab small>
              <v-icon v-if="fab">mdi-close</v-icon>
              <v-icon v-else>mdi-account-circle</v-icon>
            </v-btn>
          </template>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn fab dark small color="green" to="/profile" v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Update profile</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                color="orange"
                to="/changepassword"
                v-on="on"
              >
                <v-icon>mdi-lock-open-outline</v-icon>
              </v-btn>
            </template>
            <span>Change password</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn fab dark small color="indigo" to="/users" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add user</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn fab dark small color="red" @click="onSignOut()" v-on="on">
                <v-icon>exit_to_app</v-icon>
              </v-btn>
            </template>
            <span>Logout</span>
          </v-tooltip>
        </v-speed-dial>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <slot />
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    async onSignOut() {
      await this.$store.dispatch("logout");
      this.$router.push("/");
    },
  },
  data: () => ({
    direction: "bottom",
    hover: false,
    transition: "slide-y-reverse-transition",
    fab: false,
    isValidOperations: false,
    isConsultantRole: false,
    isAdminRole: false,
  }),
  async created() {
    const that = this;
    if (this.$store.getters.roles) {
      this.$store.getters.roles.forEach(function(role) {
        const accessRoles = ["ADMIN", "SERVICE", "DELIVERY"];
        if (accessRoles.includes(role)) {
          that.isConsultantRole = true;
        }
        const powerUserRole = ["ADMIN", "SERVICE"];
        if (powerUserRole.includes(role)) {
          that.isAdminRole = true;
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>
