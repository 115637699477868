import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Amplify } from "aws-amplify";
import Gravatar from "vue-gravatar";
import vuetify from "./plugins/vuetify";
// import "roboto-fontface/css/roboto/roboto-fontface.css";
// import "@mdi/font/css/materialdesignicons.css";
import awsconfig from "./aws-exports";
// var VueCookie = require("vue-cookie");
import Vuelidate from "vuelidate";
import { VueMaskDirective } from "v-mask";
import DefaultLayout from "./layouts/DefaultLayout";
import FullPageLayout from "./layouts/FullPageLayout";

import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";

Amplify.configure(awsconfig);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.productionTip = false;

Vue.use(Vuelidate);

// Vue.use(VueCookie);

Vue.directive("mask", VueMaskDirective);

Vue.use(require("vue-moment"));

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.component("v-gravatar", Gravatar);

Vue.config.productionTip = false;
Vue.component("DefaultLayout", DefaultLayout);
Vue.component("FullPageLayout", FullPageLayout);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// Let's style this up
require("./assets/style.css");
