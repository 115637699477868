import Vue from "vue";
import Router from "vue-router";
import { Auth } from "aws-amplify";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../views/Home.vue"),
      meta: { layout: "DefaultLayout" },
    },
    {
      path: "/booking/:eventcode",
      name: "bookevent",
      component: () => import("../views/Bookevent.vue"),
      meta: { layout: "DefaultLayout" },
    },
    {
      path: "/event/:eventType/:eventId",
      name: "event",
      component: () => import("../views/Event.vue"),
      meta: { requiresAuth: true, layout: "DefaultLayout" },
    },
    {
      path: "/manage-booking",
      name: "managebooking",
      component: () => import("../views/Managebooking.vue"),
      meta: { layout: "DefaultLayout" },
    },
    {
      path: "/client-access/:eventId",
      name: "clientaccess",
      component: () => import("../views/Clientaccess.vue"),
      meta: { layout: "DefaultLayout" },
    },
    {
      path: "/templates",
      name: "templates",
      component: () => import("../views/Templates.vue"),
      meta: {
        requiresAuth: true,
        role: ["ADMIN", "SERVICE"],
        layout: "DefaultLayout",
      },
    },
    {
      path: "/company",
      name: "company",
      component: () => import("../views/Company.vue"),
      meta: {
        requiresAuth: true,
        role: ["ADMIN", "SERVICE"],
        layout: "DefaultLayout",
      },
    },
    {
      path: "/operations",
      name: "operations",
      component: () => import("../views/Opsview.vue"),
      meta: {
        requiresAuth: true,
        role: ["ADMIN", "SERVICE", "SALES"],
        layout: "DefaultLayout",
      },
    },
    {
      path: "/events",
      name: "events",
      component: () => import("../views/Eventsview.vue"),
      meta: { requiresAuth: true, role: ["ADMIN"], layout: "DefaultLayout" }
    },
    {
      path: "/consultant",
      name: "consultant",
      component: () => import("../views/Consultantview.vue"),
      meta: {
        requiresAuth: true,
        role: ["ADMIN", "SERVICE", "DELIVERY"],
        layout: "DefaultLayout",
      },
    },
    {
      path: "/email-extractor",
      name: "emailextractor",
      component: () => import("../views/Extractor.vue"),
      meta: {
        requiresAuth: true,
        role: ["ADMIN", "SERVICE"],
        layout: "DefaultLayout"
      },
    },
    {
      path: "/login",
      name: "login",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/Login.vue"),
      meta: { layout: "FullPageLayout" },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("../views/Users.vue"),
      meta: {
        requiresAuth: true,
        role: ["ADMIN", "SERVICE"],
        layout: "DefaultLayout",
      },
    },
    {
      path: "/changepassword",
      name: "changepassword",
      component: () => import("../views/ChangePassword.vue"),
      meta: { requiresAuth: true, layout: "DefaultLayout" },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("../views/Profile.vue"),
      meta: { requiresAuth: true, layout: "DefaultLayout" },
    },
  ],
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = !!(await Auth.currentUserInfo());

  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    if (requiresAuth) {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const userRoles =
        authenticatedUser.signInUserSession.accessToken.payload[
          "cognito:groups"
        ];
      let role = to.meta.role;
      if (typeof to.meta.role !== "undefined") {
        let authorised = false;
        for (var i = 0; i < userRoles.length; i++) {
          role.forEach(function(r) {
            if (userRoles[i] === r) {
              authorised = true;
            }
          });
        }
        if (!authorised) {
          next("/");
        }
      }
    }
    next();
  }
});

export default router;
